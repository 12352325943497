import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"

// const {
//   ImageModule,
// } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ThankingTemplate = (props) => {
  const PageData = props.data?.strapiPage
  const type= typeof window!=="undefined"?window.history.state.type:""
  const currentUrl=typeof window!=="undefined"?window.history.state.currentUrl:""
  const thankingAddress=typeof window!=="undefined"?window.history.state.thankingAddress:""
  const title=typeof window!=="undefined"?window.history.state.title:"";

  // let processedImages = JSON.stringify({})
  // if (PageData?.imagetransforms) {
  //   processedImages = PageData?.imagetransforms
  // }
  // var imagename = "page.banner_section_banner_image.landing_banner_image"
  return (
    <Layout  popularSearch={PageData?.select_popular_search?.title}>
      <div className="thankyou-wrapper">
        <div className="thankingpage-banner">
          {/* <ImageModule
            ImageSrc={image}
            altText={banner_title}
            imagetransforms={processedImages}
            renderer="bgImg"
            imagename={imagename}
            strapi_id={strapi_id}
            classNames="img-fluid thanking-banner-img"
          /> */}
          <div className="thanking-banner-img" style={{backgroundImage:`url(${PageData?.banner?.image?.url})`}}></div>
         <div className="overlay-bg"></div>
         <div className="tq-banner-content">
            <h1>Thank you for registering {type=="campaign"?"":title}</h1>
            <p className="tq-desc">
             <ContentModule Content={PageData?.banner?.banner_content?.data?.banner_content} />
            </p>
            
            <Link to={currentUrl}>
              <button className="button button-filled-green">{type=="campaign"?"Back to page":"Back to event page"}</button>
            </Link>
         </div>
        </div>
        <div className="display_address">
          <Container>
              <p className="address">
                {thankingAddress}
              </p>
          </Container>
        </div>
        
      </div>
    </Layout>
  )
}

export default ThankingTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
    }
  }
`
